@import "~materialize-css/dist/css/materialize.min.css";

html, body {
    height: 100%;
    margin: 0;
}

.page-content {
    min-height: 100%;

    /* Equal to height of footer */
    /* But also accounting for potential margin-bottom of last child */
    margin-bottom: -50px;
}
footer,
.push {
    height: 50px;
}

.header_email{
    display:inline-block;
    margin-left: 30px;
    vertical-align: top;
    margin-top: 24px;
}


.collapsible-body.no-padding{
    padding:7px !important;
}

.modal.height90{
    max-height: 80% !important;
}


input.yellow-input{
    border-bottom: 1px solid #fff !important;
    box-shadow: 0 1px 0 0 #fff !important;
}

input.yellow-input + label{
    color: #ffffff !important;
}

input.yellow-input:focus + label{
    color: #ffeb3b !important;
}

input.yellow-input:focus{
    border-bottom: 1px solid #ffeb3b !important;
    box-shadow: 0 1px 0 0 #ffeb3b !important;
}

button.colorPickerBtn, div.colorPickerBtn  {
    width: 30px;
    height: 30px;
    margin: 1px;
    padding: 0;
}

span.colorPickerBtn   {
    width: 100%;
    height: 20px;
    border:1px solid #e3e3e3;
    padding: 0 3px;
    border-radius: 3px;
    display: inline-block;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    vertical-align: bottom;
}

.linebtn{
    margin-left: 1px;
    margin-bottom: 5px;
    width: 45px;
}


.EmailContentTextArea {
    resize: none;
    width: 190px;
    height: 90px;
    font-size: 10px;
}


.emailSubjectSelect {
    width: 230px;
    font-size: 10px;
}

.emailMessageContainer{
    padding: 30px;
    background-color: #fafafa;
    text-align: center;
    margin-top: 50px;
    border: 2px solid #ececec;
    border-radius: 6px;
}

.paymentMessageContainer{
    padding: 30px;
    background-color: #fafafa;
    text-align: center;
    margin-top: 50px;
    border: 2px solid #ececec;
    border-radius: 6px;
}

.small-modal{
    width: 25%;
}

.subscription-header-message{
    margin:10px;
    text-align: center;
    font-weight:bold;
    color: white;
}
.subscription-header-message div{
    border-radius: 10px;
    padding: 10px;
}
.subscription-header-message .expired{
    background-color: #ff0000;
}
.subscription-header-message .few-days-left{
    background-color: #ff8100;
}
.subscription-header-message .expires-today{
    background-color: #ff0000;
}


.profile-status{
    border-radius: 6px;
    padding: 4px;
    color: white;
}

.redirecting-message{
    border-radius: 6px;
    padding: 4px;
    color: white;
    background-color: #ff7b00;
}

.redirecting-failed
{
    border-radius: 6px;
    padding: 4px;
    color: white;
    background-color: #e63535;
}

.payment-details{

}
.payment-details p{
    margin: 2px 0;
}
.payment-details .total{
    font-size: 1.2rem;
    font-weight: bold;
}

.promocode-container{
    margin-top: 20px;
}

.promocode-container .promocode-result{
    border: 1px solid grey;
    padding: 10px;
    border-radius: 10px;
    width: fit-content !important;
}

.new-payment-months-container{
    display: inline-block;
}
.new-payment-months-container input{
    width: 40px;
    margin-left: 10px;
    border: 1px solid gray;
    border-radius: 6px;
    padding: 4px;
    height: 30px;
}

.new-payment-months-container .arrows-container{
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-left: -7px;
}
.new-payment-months-container .arrows-container button{
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    text-align: center;
}
.new-payment-months-container .arrows-container button.arrow-up{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.new-payment-months-container .arrows-container button.arrow-down{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.new-payment-months-container .arrows-container button i{
    height: 10px;
    display: block;
    margin-top: -17px;
}

@media only screen and (max-width: 992px){
    .small-modal {
        width: 80%;
    }
}


.tabs .tab a {
    color: rgba(38, 166, 154, 0.7);
    font-weight: bold;
    border:1px solid #f5f5f5;
    /*Custom Text Color*/
}

.tabs .tab a:hover , .tabs .tab a.active{
    color:#26a69a !important;
    /*Custom Color On Hover*/
}

.tabs .tab a:focus.active {
    color:#26a69a!important;
    /*Custom Text Color While Active*/
    background-color: rgba(38, 166, 154, 0.2);
    /*Custom Background Color While Active*/
}

.tabs .indicator {
    background-color:#26a69a;
    /*Custom Color Of Indicator*/
}